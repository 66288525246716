import React from "react";

export const ArrowIconPrev = ({ fill }) => (
  <svg
    width="53"
    height="15"
    viewBox="0 0 53 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.73144 1.12242L1.24535 7.50164L7.73145 13.8809"
      stroke={fill}
      strokeWidth="0.75"
    />
    <path
      d="M7.61039 1L7.85254 1.24156L1.49097 7.5017L7.85254 13.7584L7.61039 14L0.999761 7.5017L7.61039 1Z"
      fill="black"
      stroke={fill}
      strokeWidth="0.75"
    />
    <path
      d="M1.24594 7.50147L51.917 7.50146"
      stroke={fill}
      strokeWidth="0.75"
    />
    <path
      d="M1.24594 7.67188L51.917 7.67188L51.917 7.33165L1.24594 7.33165L1.24594 7.67188Z"
      fill="black"
      stroke={fill}
      strokeWidth="0.75"
    />
  </svg>
);

export const ArrowIconNext = ({ fill }) => (
  <svg
    width="53"
    height="15"
    viewBox="0 0 53 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.1855 13.8776L51.6716 7.49836L45.1855 1.11914"
      stroke={fill}
      strokeWidth="0.75"
    />
    <path
      d="M45.3066 14L45.0645 13.7584L51.426 7.4983L45.0645 1.24156L45.3066 1L51.9172 7.4983L45.3066 14Z"
      fill="black"
      stroke={fill}
      strokeWidth="0.75"
    />
    <path d="M51.6711 7.49854H1" stroke={fill} strokeWidth="0.75" />
    <path
      d="M51.6711 7.32812H1V7.66835H51.6711V7.32812Z"
      fill="black"
      stroke={fill}
      strokeWidth="0.75"
    />
  </svg>
);

export const ArrowIconSubscribe = () => (
  <svg
    width="36"
    height="11"
    viewBox="0 0 36 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5049 9.75877L34.836 5.49902L30.5049 1.23926"
      stroke="#F8F1E8"
      strokeWidth="0.6"
    />
    <path
      d="M30.5855 9.84049L30.4238 9.67918L34.6718 5.49894L30.4238 1.32097L30.5855 1.15967L34.9998 5.49894L30.5855 9.84049Z"
      fill="black"
      stroke="#F8F1E8"
      strokeWidth="0.6"
    />
    <path d="M34.8359 5.49902H1" stroke="#F8F1E8" strokeWidth="0.6" />
    <path
      d="M34.8359 5.38525H1V5.61244H34.8359V5.38525Z"
      fill="black"
      stroke="#F8F1E8"
      strokeWidth="0.6"
    />
  </svg>
);

export const MobileProjectArrow = () => (
  <svg
    width="36"
    height="11"
    viewBox="0 0 36 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5056 9.59959L34.8367 5.33984L30.5056 1.08008"
      stroke="black"
      strokeWidth="0.5"
    />
    <path
      d="M30.586 9.68082L30.4243 9.51952L34.6723 5.33927L30.4243 1.1613L30.586 1L35.0003 5.33927L30.586 9.68082Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
    <path d="M34.8359 5.33984H1" stroke="black" strokeWidth="0.5" />
    <path
      d="M34.8359 5.22559H1V5.45277H34.8359V5.22559Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
);
